body {
    -webkit-font-smoothing: antialiased;
}

.ios-notch-fix {
    background-color: #fff;
    position: fixed;
    height: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
}

@supports (constant(safe-area-inset-top)) {
    body {
        padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
            constant(safe-area-inset-bottom) constant(safe-area-inset-left);
    }

    .ios-notch-fix {
        height: constant(safe-area-inset-top);
    }
}

@supports (env(safe-area-inset-top)) {
    body {
        padding: env(safe-area-inset-top) env(safe-area-inset-right)
            env(safe-area-inset-bottom) env(safe-area-inset-left);
    }

    .ios-notch-fix {
        height: env(safe-area-inset-top);
    }
}

.MuiSnackbar-anchorOriginTopRight {
    margin-top: env(safe-area-inset-top) !important;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']:hover {
    -moz-appearance: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    display: none;
}

input[type='number']:hover::-webkit-inner-spin-button,
input[type='number']:hover::-webkit-outer-spin-button {
    display: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'BetterGrade';
    src: url(./fonts/BetterGrade.otf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: url(./fonts/Gilroy-Medium.otf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Gilroy';
    src: url(./fonts/Gilroy-SemiBold.otf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Gilroy';
    src: url(./fonts/Gilroy-Bold.otf) format('truetype');
    font-weight: bold;
}
